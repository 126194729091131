import { useState, useEffect } from "react";
import { Row, Col, Drawer, Menu, Dropdown } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import PuprLogo from "../../assets/img/logo-dit-sanitasi.png";
import PuprLogoSmall from "../../assets/img/pupr.png";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menus,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  Image,
  ImageSmall
} from "./styles";
import { Link } from "react-router-dom";


const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const [sticky, setSticky] = useState('');
  const checkScrollTop = () => {
    let customOffset = window.pageYOffset
    if (customOffset > 150) {
      setSticky('stuck')
    } else {
      setSticky('')
    }
  };

  const monitoring = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://sanitasi.ciptakarya.pu.go.id/v2/">
          SI INSAN
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://sanitasi.ciptakarya.pu.go.id/sim-ibm/">
          SIM IBM Sanitasi
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="http://34.101.182.189/balai-ui/">
          Dashboard BPPW
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="http://plpbm.pu.go.id/elearningibm/guest/">
          Materi E-Learning
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="http://plpbm.pu.go.id/elearning/users/">
          Sosial Media Klinik Sanitasi
        </a>
      </Menu.Item>
    </Menu>
  );

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <Link to="/">
            <Span>{t("Dashboard")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/profile">
            <Span>{t("Profile")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/berita">
            <Span>{t("News")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/infografis">
            <Span>{t("Infografis")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/produk-hukum">
            <Span>{t("Produk Hukum")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/informasi-publik">
            <Span>{t("Public Information")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/galeri">
            <Span>{t("Gallery")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Dropdown overlay={monitoring}>
            <a className="ant-dropdown-link button-monitoring" onClick={e => e.preventDefault()}>
              Aplikasi Monitoring
          </a>
          </Dropdown>
        </CustomNavLinkSmall>
      </>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  return (
    <HeaderSection className={sticky}>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <Image src={PuprLogo} alt="Sanitasi PUPR" />
            <ImageSmall src={PuprLogoSmall} alt="Sanitasi PUPR" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menus>Menu</Menus>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
